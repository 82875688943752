// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-celebrate-jsx": () => import("/opt/build/repo/src/pages/celebrate.jsx" /* webpackChunkName: "component---src-pages-celebrate-jsx" */),
  "component---src-pages-gift-jsx": () => import("/opt/build/repo/src/pages/gift.jsx" /* webpackChunkName: "component---src-pages-gift-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-proposal-jsx": () => import("/opt/build/repo/src/pages/proposal.jsx" /* webpackChunkName: "component---src-pages-proposal-jsx" */),
  "component---src-pages-rest-jsx": () => import("/opt/build/repo/src/pages/rest.jsx" /* webpackChunkName: "component---src-pages-rest-jsx" */),
  "component---src-pages-story-jsx": () => import("/opt/build/repo/src/pages/story.jsx" /* webpackChunkName: "component---src-pages-story-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

